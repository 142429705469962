<template>
    <div class="px-3 py-2"> 
        <v-form ref="forinv" lazy-validation>
            <b-sidebar id="add_check" aria-labelledby="sidebar-no-header-title" no-header backdrop width="95%" :style="`direction:`+lang.ldir" right :title="lang.add_check" shadow >
                <template #default="{ hide }">
                    <div class="m-1 text-start" style="direction:rtl">
                        <div style="width:140px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                            <span>{{lang.add_check}}</span>
                        </div>
                        <div @click="hide" id="HideCheckup" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                            <span>{{lang.close}}</span>
                        </div>
                    </div>
                    <v-divider></v-divider>
                    <div class="px-3 py-2" style="direction:rtl">
                        <v-row v-if="checkup.id != 0">
                            <v-col cols="12" md="12">
                                
                                <div class="backBlack" style="padding:0px;width:100%;text-align:center;">
                                    <b-button class="mytab2 btn btn-sm btn-success m-2 shokTbl" style="width:150px;background:darkblue !important;" @click="addQuot()" v-b-toggle.add_quotation>{{lang.add_quotation}}</b-button> 
                                    <b-button class="mytab2 btn btn-sm btn-success m-2 shokTbl" style="width:150px;background:darkslategrey !important;" @click="createnew()" v-b-toggle.add_new_card>{{lang.add_maint_request}}</b-button> 
                                    <b-button class="mytab2 btn btn-sm btn-success m-2 shokTbl" style="width:150px;background:green !important;" @click="createInvoice()" v-b-toggle.add_invoice>{{lang.add_invoice}}</b-button> 
                                    <b-button class="mytab2 btn btn-sm btn-default m-2 shokTbl" style="width:150px;background:darkorange !important;" @click="printIt()" >{{lang.print}} </b-button> 
                                    <b-button class="mytab2 btn btn-sm btn-default m-2 shokTbl" style="width:150px;background:black !important;" v-b-toggle.viewCheckupGallery @click="getAttached()">المرفقات </b-button> 
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="2" sm="6" xs="12">
                                <label>{{ lang.checkup_type }}</label>
                                <b-input-group>
                                    <b-form-select
                                        class="selborder"
                                        v-model="checkup.checkup_type"
                                        :options="checkUpTypes"
                                        @change="InvType()"
                                        style="background:green !important"
                                    ></b-form-select>
                                    <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                                        <i class="fas fa-arrow-down"></i>
                                    </b-input-group-append>
                                </b-input-group>
                            </v-col>
                            <v-col cols="12" md="2" sm="6" xs="12">
                                <label>{{ lang.mobile }}</label>
                                <b-form-input
                                    class="inborder"
                                    v-model="checkup.mobile"
                                    @change="getCustomer()"
                                ></b-form-input>
                            </v-col>
                            <v-col cols="12" md="2" sm="6" xs="12">
                                <label v-if="showInsurance">{{ lang.insurance_company }}</label>
                                <label v-if="!showInsurance">{{ lang.customer_name }}</label>
                                <b-form-input
                                    class="inborder"
                                    v-model="checkup.customer_name"
                                    readonly
                                ></b-form-input>
                            </v-col>
                            <v-col cols="12" md="2" sm="6" xs="12">
                                <label>{{ lang.owner_name }}</label>
                                <b-form-input
                                    class="inborder"
                                    v-model="checkup.owner_name"
                                ></b-form-input>
                            </v-col>
                            <v-col cols="12" md="2" sm="6" xs="12">
                                <label>{{ lang.delivered_by }}</label>
                                <b-form-input
                                    class="inborder"
                                    v-model="checkup.delivered_by"
                                ></b-form-input>
                            </v-col>
                            <v-col cols="12" md="2" sm="6" xs="12" v-if="showContract">
                                <label>{{ lang.contract_number }}</label>
                                <b-form-input
                                    class="inborder"
                                    v-model="checkup.contract_number"
                                ></b-form-input>
                            </v-col>
                            <v-col cols="12" md="2" sm="6" xs="12" v-if="$store.state.licenseType.showCheckBranch">
                                <label>{{ lang.branch_name }}</label>
                                <b-input-group>
                                    <b-form-select
                                        class="selborder"
                                        v-model="checkup.branch"
                                        :options="branchesList"
                                    />
                                    <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                                            <i class="fas fa-arrow-down"></i>
                                    </b-input-group-append>
                                </b-input-group>
                            </v-col>
                            <v-col cols="12" md="6" sm="12" xs="12">
                                <div class="flexMe">
                                    <div>
                                        <addCarScetch ref="firstCar" :CarsList="AcurrentCars" :carFirst="checkup.plate_number" />
                                    </div>
                                    <div class="text-center buttonFlex" style="width:100px;margin:auto">
                                        <div style="width:100px;white-space: nowrap;padding-inline:5px;">اختر الاجراء </div>
                                        <v-btn style="width:100%;box-shadow:none;color:#fff;background:blue !important;border:1px solid #ccc !important;" @click.prevent="$refs.firstCar.$refs.VueCanvasDrawing.reset()">{{lang.reset}}</v-btn>
                                        <v-btn style="width:100%;box-shadow:none;color:#fff;background:red !important;border:1px solid #ccc !important;" @click.prevent="$refs.firstCar.$refs.VueCanvasDrawing.undo()">{{lang.undo}}</v-btn>
                                        <v-btn style="width:100%;box-shadow:none;color:#fff;background:green !important;border:1px solid #ccc !important;" @click="save()">{{lang.save}}</v-btn>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="12" md="6" sm="12" xs="12">
                                <v-row>
                                    <v-col cols="12" md="6" sm="12">
                                        <label>{{ lang.owner_mobile_number }}</label>
                                        <b-form-input
                                            class="inborder"
                                            v-model="checkup.owner_mobile_number"
                                        ></b-form-input>
                                    </v-col>
                                    <v-col cols="12" md="6" sm="12">
                                        <label>{{ lang.status }}</label>
                                        <b-input-group>
                                            <b-form-select
                                                class="selborder"
                                                v-model="checkup.status"
                                                :options="checkStatuss"
                                            ></b-form-select>
                                            <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                                                <i class="fas fa-arrow-down"></i>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </v-col>
                                    <v-col cols="12" md="6" sm="12">
                                        <label>{{ lang.plate_number }}</label>
                                        <b-form-input
                                            class="inborder"
                                            v-model="checkup.plate_number"
                                        ></b-form-input>
                                    </v-col>
                                    <v-col cols="12" md="6" sm="12">
                                        <label>{{ lang.brand }}</label>
                                        <b-form-input
                                            class="inborder"
                                            v-model="checkup.brand"
                                        ></b-form-input>
                                    </v-col>
                                    <v-col cols="12" md="6" sm="12">
                                        <label>{{ lang.car_model }}</label>
                                        <b-form-input
                                            class="inborder"
                                            v-model="checkup.model"
                                        ></b-form-input>
                                    </v-col>
                                    <v-col cols="12" md="3" sm="12">
                                        <label>{{ lang.car_year }}</label>
                                        <b-form-input
                                            class="inborder"
                                            v-model="checkup.made_year"
                                        ></b-form-input>
                                    </v-col>
                                    <v-col cols="12" md="3" sm="12">
                                        <label>{{ lang.color }}</label>
                                        <b-form-input
                                            class="inborder"
                                            v-model="checkup.color"
                                        ></b-form-input>
                                    </v-col>
                                    <v-col cols="12" md="4" sm="12">
                                        <label>{{ lang.vin }}</label>
                                        <b-form-input
                                            class="inborder"
                                            v-model="checkup.vin"
                                        ></b-form-input>
                                    </v-col>
                                    <v-col cols="12" md="4" sm="12">
                                        <label>{{ lang.engine_meter }}</label>
                                        <b-form-input
                                            class="inborder"
                                            v-model="checkup.engine_meter"
                                        ></b-form-input>
                                    </v-col>
                                    <v-col cols="12" md="4" sm="12">
                                        <label>{{ lang.po_number }}</label>
                                        <b-form-input
                                            class="inborder"
                                            v-model="checkup.po_number"
                                        ></b-form-input>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-row>
                                    <v-col cols="12" md="6" sm="12">
                                        <label>{{ lang.car_received_status }}</label>
                                        <v-simple-table striped hover style="width:100%;">
                                            <thead>
                                                <tr>
                                                    <th class="backBlack" style="border:1px solid black;">{{ lang.description }}</th>
                                                    <th class="backBlack text-center" style="border:1px solid black;width:110px;">{{ lang.direction }}</th>
                                                    <th class="backBlack text-center" style="border:1px solid black;width:70px;">{{ lang.action }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, index) in checkup.statuses" :key="index">
                                                    <td style="border:1px solid black;">
                                                        <b-form-input v-model="checkup.statuses[index].description" class="inborder" />
                                                    </td>
                                                    <td style="border:1px solid black;">
                                                        <b-input-group>
                                                            <b-form-select v-model="checkup.statuses[index].direction" :options="checkDirection" class="selborder" />
                                                            <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                                                                <i class="fas fa-arrow-down"></i>
                                                            </b-input-group-append>
                                                        </b-input-group>
                                                    </td>
                                                    <td @click="delSTatus()" style="cursor:pointer;border:1px solid black;background:red !important;color:#fff !important;text-align:center;">
                                                        {{ lang.delete }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td style="border:1px solid black;">
                                                        <b-form-input v-model="statuses.description" class="inborder" />
                                                    </td>
                                                    <td style="border:1px solid black;">
                                                        <b-input-group>
                                                            <b-form-select v-model="statuses.direction" :options="direct" class="selborder" />
                                                            <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                                                                <i class="fas fa-arrow-down"></i>
                                                            </b-input-group-append>
                                                        </b-input-group>
                                                    </td>
                                                    <td @click="AddStatus()" style="cursor:pointer;border:1px solid black;background:green !important;color:#fff !important;text-align:center;">
                                                        {{ lang.add }}
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </v-simple-table>
                                    </v-col>
                                    <v-col cols="12" md="6" sm="12">
                                        <label>{{ lang.checkup_notes }}</label>
                                        <v-simple-table striped hover style="width:100%;">
                                            <thead>
                                                <tr>
                                                    <th class="backBlack" style="border:1px solid black;">{{ lang.description }}</th>
                                                    <th class="backBlack text-center" style="border:1px solid black;width:70px;">{{ lang.action }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, index) in checkup.itemsIn" :key="index">
                                                    <td style="border:1px solid black;">
                                                        <b-form-input v-model="checkup.itemsIn[index].description" class="inborder" />
                                                    </td>
                                                    <td @click="delSItemIn()" style="cursor:pointer;border:1px solid black;background:red !important;color:#fff !important;text-align:center;">
                                                        {{ lang.delete }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td style="border:1px solid black;">
                                                        <b-input-group>
                                                            <b-form-select v-model="itemsIn" :options="checkUpItemsIns" class="selborder" />
                                                            <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                                                                <i class="fas fa-arrow-down"></i>
                                                            </b-input-group-append>
                                                        </b-input-group>   
                                                    </td>
                                                    <td @click="AddItemIn()" style="cursor:pointer;border:1px solid black;background:green !important;color:#fff !important;text-align:center;">
                                                        {{ lang.add }}
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </v-simple-table>
                                    </v-col>
                                    <v-col cols="12" md="6" sm="12">
                                        <label>{{ lang.maintenance_required }}</label>
                                        <v-simple-table striped hover style="width:100%;">
                                            <thead>
                                                <tr>
                                                    <th class="backBlack" style="width:100px;border:1px solid black;">{{ lang.item_code }}</th>
                                                    <th class="backBlack" style="border:1px solid black;">{{ lang.description }}</th>
                                                    <th class="backBlack text-center" style="border:1px solid black;width:80px;">{{ lang.action }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, index) in checkup.maints" :key="index">
                                                    <td style="border:1px solid black;">
                                                        <b-form-input v-model="checkup.maints[index].item_code" class="inborder" />
                                                    </td>
                                                    <td style="border:1px solid black;">
                                                        <b-form-input v-model="checkup.maints[index].description" class="inborder" />
                                                    </td>
                                                    <td @click="delMaint()" style="cursor:pointer;border:1px solid black;background:red !important;color:#fff !important;text-align:center;">
                                                        {{ lang.delete }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td style="border:1px solid black;">
                                                        <b-form-input v-model="maints.item_code" class="inborder" />
                                                    </td>
                                                    <td style="border:1px solid black;">
                                                        <b-form-input v-model="maints.description" class="inborder" />
                                                    </td>
                                                    <td @click="AddMaint()" style="cursor:pointer;border:1px solid black;background:green !important;color:#fff !important;text-align:center;">
                                                        {{ lang.add }}
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </v-simple-table>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" md="12" class="text-center">
                                <img :src="checkup.images" v-if="checkup.images != ''"  />
                            </v-col>
                        </v-row>
        <viewCheckupGallery ref="viewCheckupGallery" />
        <addNewCard ref="createnew" />
        <addInvoice ref="createInvoice" />
        <AddQuotation ref="addQuot" :cust='custo' />
                    </div>
                </template>
                <template #footer="" class="shadow">
                    <div class="d-flex text-light align-items-center px-3 py-5">
                        <b-button type="button" @click="addCheckup()" ref="addButton" id="addBTN" variant="success" class="ma-2 py-3 btn-sm" style="width:170px;font-size:1.3rem !important;">{{lang.save_n_print}}</b-button>
                    </div>
                </template>
            </b-sidebar>
        </v-form>
        <vue-snotify></vue-snotify>
    </div>
</template>

<script>
import axios from 'axios'
import {SnotifyPosition} from 'vue-snotify';
import addCarScetch from '@/components/addCarScetch.vue'
import viewCheckupGallery from '@/components/viewCheckupGallery.vue'
import addNewCard from "@/components/addNewCard.vue"
import AddQuotation from '@/components/addQuotation.vue';
import addInvoice from '@/components/addInvoice.vue';

export default {
    components: {addCarScetch,viewCheckupGallery,addNewCard,AddQuotation,addInvoice},
    data() {
        return {
            custo: '',
            branches: [],
            itemsIn: '',
            AcurrentCars: [{
                plate_number: '',
                color: '',
                model: '',
                made_year: '',
                brand: '',
                vin: '',
                engine_meter: '',
            }],
            statuses: {
                description: "",
                direction: 1,
            },
            maints: {
                item_code: "",
                description: "",
            },
            checkup: {
                id: 0,
                branch: '',
                checkup_type: 2,
                mobile: '',
                customer_name: '',
                customer_id: 0,
                contract_number: 0,
                plate_number: '',
                brand: '',
                model: '',
                made_year: '',
                color: '',
                vin: '',
                engine_meter: '',
                owner_name: '',
                delivered_by: '',
                statuses: [],
                maints: [],
                images: '',
                status: 1,
                owner_mobile_number: '',
                po_number: '',
                itemsIn: []
            },
            checkTypes: [
                { value: 1, name_ar: 'شركات تأمين', name_en: 'Insurance Co'},
                { value: 2, name_ar: 'فحص عام', name_en: 'General Check'},
                // { value: 3, name_ar: 'عقود صيانة', name_en: 'Contracts'},
            ],
            receivedProc: [],
 
            showInsurance: false,
            showGeneral: true,
            showContract: false,
            checkUpStatus: [],
            checkUpItemsIn: [],
        }
    },
    computed:{
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },

        checkStatuss: function(){
          let t = [];
          for(let i=0; i< this.checkUpStatus.length;i++){
            t.push({text: this.checkUpStatus[i][`name_${this.lang.langname}`], value: this.checkUpStatus[i][`name_${this.lang.langname}`]})
          }
          return t;
        },
        checkDirection: function(){
            let t = [];
            for(let i=0; i< this.receivedProc.length;i++){
                t.push({text: this.receivedProc[i][`name_${this.lang.langname}`], value: this.receivedProc[i][`name_${this.lang.langname}`]})
            }
            return t;
        },
        branchesList: function(){
          let t = [];
          for(let i=0; i< this.branches.length;i++){
            t.push({text: this.branches[i][`name_${this.lang.langname}`], value: this.branches[i][`name_${this.lang.langname}`]})
          }
          return t;
        },
        checkUpItemsIns: function(){
          let t = [];
          for(let i=0; i< this.checkUpItemsIn.length;i++){
            t.push({text: this.checkUpItemsIn[i][`name_${this.lang.langname}`], value: this.checkUpItemsIn[i][`name_${this.lang.langname}`]})
          }
          return t;
        },
        checkUpTypes: function(){
            let t = [];
            for(let i=0;i<this.checkTypes.length;i++){
                t.push({value: this.checkTypes[i].value, text: this.checkTypes[i][`name_${this.lang.langname}`]})
            }
            return t;
        },
        direct: function(){
            let t = [];
            for(let i=0;i<this.checkDirection.length;i++){
                t.push({value: this.receivedProc[i][`name_${this.lang.langname}`], text: this.receivedProc[i][`name_${this.lang.langname}`]})
            }
            return t;
        }
    },
    methods: {
        async createInvoice()
        {
            await this.$refs.createInvoice.getNewInvoiceID();
            this.$refs.createInvoice.reset1();
            this.$refs.createInvoice.cars = [{
                c1: '',
                c2: '',
                c3: '',
                text: '',
                plate_number: '',
                color: '',
                model: '',
                made_year: '',
                workdes: '',
                vin: '',
                engine_meter: '',
                next_oil_change: '',
                brand: ''
            }];
            this.$refs.createInvoice.getCheckup(this.checkup.id);
        },
        async createnew() {
            await this.$refs.createnew.resetValues();
            this.$refs.createnew.checkUpId = this.checkup.id
            await this.$refs.createnew.getInvSettings();
            // this.$refs.createnew.$refs.firstCar.images = '';
            this.$refs.createnew.getCheckup();
        },
        async addQuot(){
            await this.$refs.addQuot.reset();
            this.$refs.addQuot.id = 0;
            this.$refs.addQuot.checkUpId = this.checkup.id
            this.$refs.addQuot.getCheckup()
        },
        printIt ()
        {
            let src = '';
                src = '../api/print.php?checkId='+this.checkup.id;
                window.open(src,"_blank")
        },
        getAttached(){
            this.$refs.viewCheckupGallery.card.cardid = this.checkup.id
            this.$refs.viewCheckupGallery.getImages();
        },
        getStaticss(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","getStaticOptions");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[phrase]','checkUpItemsIn');
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
            this.checkUpItemsIn = response.data.results.data
            });
        },
        getBranchesList(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","getStaticOptions");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[phrase]','BranchesList');
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
            this.branches = response.data.results.data
            });
        },
        getreceivedProc(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","getStaticOptions");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[phrase]','receivedProc');
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                console.log("receivedProc33", response.data.results.data)
                this.receivedProc = response.data.results.data
            });
        },
        // getActionList(){
        //     const post = new FormData();
        //     post.append('defBranch',localStorage.getItem('defBranch'));
        //     post.append('financeYear',localStorage.getItem('financeYear'));
        //     post.append("type","getStaticOptions");
        //     post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
        //     post.append('data[phrase]','receivedProc');
        //     axios.post(
        //         this.$store.state.SAMCOTEC.r_path, post
        //     ).then((response) => {
        //         this.receivedProc = response.data.results.data
        //     });
        // },
        getStatics(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","getStaticOptions");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[phrase]','checkUpStatuses');
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
            this.checkUpStatus = response.data.results.data
            });
        },
        getCheckup(id) {
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","getCurrentCheckup");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',id);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data.results.data[0]
                this.checkup = {
                    id: res.id,
                    branch: res.branch,
                    checkup_type: res.checkup_type,
                    mobile: res.mobile,
                    customer_name: res.customer_name,
                    customer_id: res.customer_id,
                    contract_number: res.contract_number,
                    plate_number: res.plate_number,
                    brand: res.brand,
                    model: res.model,
                    made_year: res.made_year,
                    color: res.color,
                    vin: res.vin,
                    engine_meter: res.engine_meter,
                    owner_name: res.owner_name,
                    delivered_by: res.delivered_by,
                    statuses: res.statuses,
                    maints: res.maints,
                    itemsIn: res.itemsIn,
                    images: res.images,
                    status: res.status,
                    owner_mobile_number: res.owner_mobile_number,
                    po_number: res.po_number,
                }
                
                // for(let i=0; i< res.maints.length; i++){
                //     this.checkup.maints.push( res.maints[i].description)
                // }
            });
            
        },
        addCheckup() {
            let addIt = true;
            let message = '';
            if(this.checkup.mobile == ''){
                addIt = false;
                if(this.checkup.checkup_type == 1){
                    message = this.lang.insurance_company_required
                }else{
                    message = this.lang.customer_required
                }
            }
            if(this.checkup.customer_name == ''){
                addIt = false;
                if(this.checkup.checkup_type == 1){
                    message = this.lang.insurance_company_required
                }else{
                    message = this.lang.customer_required
                }
            }
            if(this.checkup.customer_id == 0){
                addIt = false;
                if(this.checkup.checkup_type == 1){
                    message = this.lang.insurance_company_required
                }else{
                    message = this.lang.customer_required
                }
            }
            if(this.checkup.plate_number == 0){
                addIt = false;
                message = this.lang.plate_number_required
            }
            if(this.checkup.model == 0){
                addIt = false;
                message = this.lang.car_model_required
            }
            if(this.checkup.made_year == 0){
                addIt = false;
                message = this.lang.made_year_required
            }
            if(this.checkup.brand == 0){
                addIt = false;
                message = this.lang.car_brand_required
            }
            if(this.checkup.color == 0){
                addIt = false;
                message = this.lang.car_color_required
            }
            if(this.checkup.statuses.length == 0){
                addIt = false;
                message = this.lang.please_add_one_car_received_status
            }
            if(this.checkup.maints.length == 0){
                addIt = false;
                message = this.lang.please_add_one_car_maintenance
            }

            if(!addIt){
                this.$snotify.error(message, this.lang.alert, {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            if(this.checkup.branch == ''){
                this.checkup.branch = 'فرع جدة';
            }
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","addCheckup");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[checkup]',JSON.stringify(this.checkup));
            this.$refs.addButton.disabled = true;
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                message = this.lang.added_successfuly
                this.$snotify.error(message, this.lang.alert, {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                document.getElementById('HideCheckup').click();
                this.$parent.getCheckup();
            });

        },
        AddStatus() {
            if(this.statuses.description == ''){
                let message = this.lang.description_required;
                this.$snotify.error(message, this.lang.alert, {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            this.checkup.statuses.push({description: this.statuses.description, direction: this.statuses.direction})
            this.statuses.description = '';
        },
        AddItemIn() {
            if(this.itemsIn == ''){
                let message = "يجب اضافة وصف لموجودات السيارة";
                this.$snotify.error(message, this.lang.alert, {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            this.checkup.itemsIn.push({description: this.itemsIn})
            this.itemsIn = '';
        },
        delSTatus(item){
            this.checkup.statuses.splice(item, 1);
            this.calcAll()
        },
        delSItemIn(item){
            this.checkup.itemsIn.splice(item, 1);
            this.calcAll()
        },
        delMaint(item){
            this.checkup.maints.splice(item, 1);
            this.calcAll()
        },
        AddMaint() {
            if(this.maints.description == ''){
                let message = this.lang.description_required;
                this.$snotify.error(message, this.lang.alert, {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            this.checkup.maints.push({description:  this.maints.description, item_code: this.maints.item_code})

            this.maints.item_code = '';
            this.maints.description = '';
        },
        save() {
            if(this.$refs.firstCar.image == ''){
                let message = this.lang.plase_select_car_scratch;
                this.$snotify.error(message, this.lang.alert, {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            this.checkup.images = this.$refs.firstCar.image
        },
        InvType() {
            if(this.checkup.checkup_type == 1){
                this.showContract = false;
                this.showGeneral = false;
                this.showInsurance = true;
            }
            else if(this.checkup.checkup_type == 2){
                this.showContract = false;
                this.showGeneral = true;
                this.showInsurance = false;
            }
            else if(this.checkup.checkup_type == 3){
                this.showContract = true;
                this.showGeneral = false;
                this.showInsurance = false;
            }
        },
        reset(){
            this.checkup = {
                id: 0,
                checkup_type: 2,
                mobile: '',
                customer_name: '',
                customer_id: 0,
                contract_number: 0,
                plate_number: '',
                brand: '',
                model: '',
                made_year: '',
                color: '',
                vin: '',
                engine_meter: '',
                owner_name: '',
                delivered_by: '',
                statuses: [],
                maints: [],
                itemsIn: [],
                images: '',
                status: 1,
                owner_mobile_number: '',
                branch: '',
                po_number: '',
            }
            this.$refs.firstCar.$refs.VueCanvasDrawing.reset()
        },
        getCustomer()
        {
            if(this.checkup.mobile == '') return false;
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','customerInfoAll');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[mobile]',this.checkup.mobile);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                console.log(response);
                if(response.data.results.data.length == 0){
                    let message = "العميل غير موجود يرجى اضافة العميل من قائمة العملاء في قسم المبيعات";
                    this.$snotify.error(message, this.lang.alert, {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                    return false;
                }
                const customer = response.data.results.data[0]
                if(this.checkup.checkup_type == 1 && customer.customer_type != 2){
                    let message = "العميل المدخل ليس شركة";
                    this.$snotify.error(message, this.lang.alert, {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                    return false;
                }
                this.checkup.customer_id = customer.id
                if(customer.customer_type == 2){
                    this.checkup.customer_name = customer.company_name
                }
                else if(customer.customer_type == 1){
                    this.checkup.customer_name = customer.full_name
                }
                else if(customer.customer_type == 3){
                    this.checkup.customer_name = customer.entity_name
                }
            })
        }
    },
    created() {
        this.getStaticss();
        this.getreceivedProc();
        this.getBranchesList();
        this.getStatics();
    }
}
</script>

<style>
.dispFlex{
    display:flex;
    flex-direction: row;
    justify-content: center !important;
    padding-left:10px;
    padding-right:10px;
    justify-items: center;
    align-items: center;
}
.boDry:hover{
    background:green !important;
}
.addCar{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
}
.tobbuttom {
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
}
.redColor {
    background: #ccc!important;
    color: #fff!important;
}
.weightColor {
    background: #fff !important;
    color: #000 !important;
}
.borderRad_none, .borderRad_right {
    border-top-left-radius: 0 0 !important;
    border-bottom-left-radius: 0 0 !important;
    padding: 5px 10px !important;
    font-size: .8rem !important;
}
.hideme{
    display:none !important;
}
.borderRad_none {
    border-top-right-radius: 0 0 !important;
    border-bottom-right-radius: 0 0 !important;
}
.borderRad_left {
    border-top-left-radius: 5px 5px !important;
    border-bottom-left-radius: 5px 5px !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    padding: 5px 10px !important;
    font-size: .8rem !important;
    -webkit-margin-start: 5px !important;
    margin-inline-start: 5px !important;
}
.col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1{
    padding: 6px 12px;
}
.flexDis{
    margin:5px;
    display:flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
    flex-basis: 100%;
    flex-wrap: wrap;
    width:100%;
}
.flexMe{
    margin:auto;
    display:flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
}
.buttonFlex{
    margin:auto;
    display:flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
}
.plateNumber{
    margin:auto;
    display:flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
}
.flexTay{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    justify-items: center;
}
.flexTay select{
    margin-inline-end: 2px;
}
.btnShow{
    display:flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
}
.shokTbl{
    border: 2px solid rgb(255, 255, 255) !important;
    font-size: 1.1rem;
    color: rgb(255, 255, 255);
    width: 130px;
    font-weight: bolder;
    overflow-anchor: none;
}
</style>
